import React from "react";
// import MilestoneRow from "../Milestones/MilestoneRow";
import {WARNING_TABLE as style} from "../../Helpers/styleconstants";

function Warning({warning, selectedAges, language}) {
    return (
        <>
            <div className={style.warningSpace}></div>
            <div className={style.warningCategory}>
                <div className={style.row}>
                    <div className={style.warningImageCell}>
                        <img className={style.warningImage} src={require('../../Images/' + warning.icon)} alt={warning.id}></img>
                    </div>
                    <div className={style.warningDescriptionCell}>
                        <div className={style.table}>
                            <div className={style.tableRow}>
                                <div className={style.tableHeader}>
                                    {warning.heading}
                                </div>
                            </div>
                            <div className={style.tableRow}>
                                <div className={style.tableColumn} dangerouslySetInnerHTML={{ __html: warning.full }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <MilestoneRow selectedAges={selectedAges} domain={warning.id}  language={language}/> */}
            </div>
        </>
    );
}

export default Warning;