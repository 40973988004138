import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import {render} from 'react-dom';
import { HashRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import "foundation-sites/dist/css/foundation.min.css";
import "./Style/main.scss"
import GA4React from "ga-4-react";

try {
    setTimeout(_ => {
      const ga4react = new GA4React("G-9WYHVWXQJW");
      ga4react.initialize().catch(err => console.error(err));
    }, 4000);
  } catch (err) {
        console.error(err);
  }

render(
    <Router>
        <App/>
    </Router>,
    document.getElementById('root'));

