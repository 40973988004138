import React, {useEffect, useState} from 'react';
import {Route, Switch} from "react-router-dom";
import {APP_ENTRY as style} from "./Helpers/styleconstants";
import StickyHeader from "./Components/Common/StickyHeader";
import {Footer} from "./Components/Common/Footer";
import HomePage from "./Components/Pages/HomePage";
import GeldsPage from "./Components/Pages/GeldsPage";
import ActEarlyPage from "./Components/Pages/ActEarlyPage";
import ResourcesPage from "./Components/Pages/ResourcesPage";
import DevelopmentalChecklistsPage from "./Components/Pages/DevelopmentalChecklistsPage";
import DecalPage from "./Components/Pages/DecalPage";
import {PageNotFound} from "./Components/Pages/PageNotFound";

function App() {
    const [language, setLanguage] = useState("English");

    const languageChangeClick = (e) => {
        setLanguage(e.target.value);
    }

    useEffect(()=>{

    },[language]);

    return (
        <div className={style.content} role="main">
            <StickyHeader userLanguage={language.toLowerCase()} handleLanguageChange={languageChangeClick} />
            <Switch>
                <Route path="/gelds" component={() => <GeldsPage language={language.toLowerCase()}/>}/>
                <Route path="/decal" component={() => <DecalPage language={language.toLowerCase()}/>}/>
                <Route path="/actearly" component={() => <ActEarlyPage language={language.toLowerCase()}/>}/>
                <Route path="/resources/:categoryId" component={() => <ResourcesPage language={language.toLowerCase()}/>} />
                <Route path="/resources" component={() => <ResourcesPage language={language.toLowerCase()}/>} />
                <Route path="/checklist" component={() => <DevelopmentalChecklistsPage language={language.toLowerCase()}/>}/>
                <Route exact path="/" component={() => <HomePage language={language.toLowerCase()}/>} />
                <Route component={PageNotFound} />
            </Switch>
            <Footer language={language.toLowerCase()}/>
        </div>
    );
}

export default App;
