import React from "react";
import AgeButton from "./AgeButton";
import {AGE_BUTTON as style} from "../../Helpers/styleconstants";

const AgeButtonGroup = ({ageLabels, selectedAges, buttonClick, language}) => {
    const buttons = ageLabels.map(buttonTags => {
        let isSelected = selectedAges.includes(buttonTags.value);
        return <AgeButton key={buttonTags.value} buttonTags={buttonTags} isSelected={isSelected}
                          buttonClick={buttonClick}/>
    });

    const buttonsPerRow = 6;

    const sectionHeader = (language === "spanish")
    ? <p>Seleccione hasta 3 grupos de edad</p>
    : (language === "mandarin")
    ? <p>最多选择3个年龄组</p>
    : (language === "korean")
    ? <p>최대 3가지 연령 그룹 선택</p>
    : (language === "vietnamese")
    ? <p>Chọn tối đa 3 Nhóm tuổi</p>
    : <p>Select up to 3 Age Groups</p>

    return (
        <div className={style.buttonContainer}>
            <span className={style.buttonLabel}>{sectionHeader}</span>
            <div className={style.buttonGroup}>
                {buttons.slice(0, buttonsPerRow)}
            </div>
            <div className={style.buttonGroup}>
                {buttons.slice(buttonsPerRow, buttons.length)}
            </div>
        </div>
    )
};

export default AgeButtonGroup;