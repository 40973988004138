import React from "react";
import MilestoneCell from "./MilestoneCell";
import {getMilestone, getLanguageOperators} from "../../repository/milestonesRepository";
import {MILESTONE_ROW as style} from "../../Helpers/styleconstants";

function MilestoneRow({selectedAges, domain, language}){
    return (
    <div className={style.milestoneRow}>
        {selectedAges.map(age => {
            let milestone = getMilestone(age, language);
            let milestoneCount = selectedAges.length;
            let modalOperators = getLanguageOperators(language);
            return (
                <MilestoneCell key={domain + age} age={age} ageLabel={milestone[0].label} ageAbbreviation={milestone[0].age} milestone={milestone[0][domain]} milestoneCount={milestoneCount} language={language} modalOperators={modalOperators} />
            )
        })}
    </div>
    );
};

export default MilestoneRow;

