import React from "react";
import {NavLink} from "react-router-dom";
import {RESOURCES_PAGE as style} from "../../Helpers/styleconstants";

function ResourceLink({resourceCollection, isSelected}) {
    return (
        <div className={isSelected ? style.selected : style.resourceLinkText}>
            <div className={style.resourceLinkContainer}>
            <NavLink to={`/resources/${resourceCollection.category[0].id}`} activeClassName={style.selected}>{resourceCollection.category[0].title}</NavLink>
            </div>
        </div>
    );
}

export default ResourceLink;