import React from "react";
import {ACTEARLY_PAGE as style} from "../../Helpers/styleconstants";
import Banner from "../Common/Banner";

function ActEarlyPage({language}) {
    const actearly = require('../../Data/ActEarlyPage.json');

    console.log(actearly);

    return (
        <>
            <Banner page={"actearly"} language={language}/>
            <div className={style.container}>
                <div className={style.header}>{actearly[language].pageTitle}</div>
                <div className={style.actEarlyMainContainer}>
                    <div className={style.actEarlyItemContainer}>
                        <div className={style.actEarlyStepLargeContainer}>
                            <div className={style.actEarlyStepContentContainer + " border-purple text-blue"}>
                                <div>
                                    <h2 dangerouslySetInnerHTML={{__html: actearly[language].sections.actearly.title}}>
                                    </h2>
                                </div>
                                <div>
                                    <p dangerouslySetInnerHTML={{__html: actearly[language].sections.actearly.text[0].p}}>
                                    </p>
                                    <p dangerouslySetInnerHTML={{__html: actearly[language].sections.actearly.text[1].p}}>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.actEarlyItemContainer}>
                        <div className={style.actEarlyStepLargeContainer + " actearly-container-arrow actearly-arrow-down"}>

                        </div>
                    </div>
                    <div className={style.actEarlyItemContainer}>
                        <div className={style.actEarlyStepLargeContainer}>
                            <div className={style.actEarlyStepContentContainer + " border-purple background-pink"}>
                                <h2 dangerouslySetInnerHTML={{__html: actearly[language].sections.hmg.title}}>
                                </h2>
                                <p dangerouslySetInnerHTML={{__html: actearly[language].sections.hmg.text[0].p}}>
                                </p>
                                <p dangerouslySetInnerHTML={{__html: actearly[language].sections.hmg.text[1].p}}>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={style.actEarlyItemContainer}>
                        <div className={style.actEarlyStepLargeContainer + " actearly-container-arrow actearly-arrow-down"}>

                        </div>
                    </div>
                    <div className={style.actEarlyItemContainer}>
                        <div className={style.actEarlyStepLargeContainer}>
                            <div className={style.actEarlyStepContentContainer + " border-purple background-red"}>
                                <h2 dangerouslySetInnerHTML={{__html: actearly[language].sections.gdoh.title}}>
                                </h2>
                                <p dangerouslySetInnerHTML={{__html: actearly[language].sections.gdoh.text[0].p}}>
                                </p>
                                <p dangerouslySetInnerHTML={{__html: actearly[language].sections.gdoh.text[1].p}}>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={style.actEarlyItemContainer}>
                        <div className={style.actEarlyStepLargeContainer + " actearly-container-arrow actearly-arrow-cross"}>
                            
                        </div>
                    </div>
                    <div className={style.actEarlyItemContainer}>
                        <div className={style.actEarlyStepSmallContainer}>
                            <div className={style.actEarlyStepContentContainer + " text-fuschia"}>
                                <h2 dangerouslySetInnerHTML={{__html: actearly[language].sections.under3.title}}>
                                </h2>
                            </div>
                            <div className={style.actEarlyStepContentContainer + " border-purple background-fuschia"}>
                                <p dangerouslySetInnerHTML={{__html: actearly[language].sections.under3.text[0].p}}>
                                </p>
                                <p dangerouslySetInnerHTML={{__html: actearly[language].sections.under3.text[1].p}}>
                                </p>
                            </div>
                        </div>
                        <div className={style.actEarlyStepSmallContainer}>
                            <div className={style.actEarlyStepContentContainer + " text-jade"}>
                                <h2 dangerouslySetInnerHTML={{__html: actearly[language].sections.over3.title}}>
                                </h2>
                            </div>
                            <div className={style.actEarlyStepContentContainer + " border-purple background-jade"}>
                                <p dangerouslySetInnerHTML={{__html: actearly[language].sections.over3.text[0].p}}>
                                </p>
                                <p dangerouslySetInnerHTML={{__html: actearly[language].sections.over3.text[1].p}}>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActEarlyPage;