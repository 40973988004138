import React from "react";
import {BANNER as style} from "../../Helpers/styleconstants";

function Banner({page, language}) {
    const banners = require( "../../Data/Banner.json");
    const banner = banners[language].filter((banner)=> {return banner.page === page});
    if (!banner || banner.length === 0)
        return null;
    return (
        <>
            <div className={style.bannerContainer} aria-roledescription="banner">
                <div className={style.bannerImage}>
                    <img src={require('../../Images/' + banner[0].image)} alt="Developmental Milestones Banner"/>
                </div>
            </div>
            <div className={style.bannerContainer}>
                <div className={style.bannerTitle}>
                    {banner[0].title}
                </div>
            </div>
            <div className={style.bannerContainer}>
                <div className={style.bannerText}>
                    {banner[0].text}
                </div>
            </div>
        </>
    )
}

export default Banner;