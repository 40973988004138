import {useEffect, useState} from "react";

export function useFormInput(initialValue) {
    const [value, setValue] = useState(initialValue);

    function handleChange(e) {
        const value = e && e.target ? e.target.value : e;
        console.log(value);
        setValue(value);
    }

    return {
        onChange: handleChange,
        setValue,
        value
    };
}

export function useMemento(initialValue) {
    const [value, setValue] = useState(initialValue);
    const [mementoValue, setMementoValue] = useState([]);

    function setStateValue(valueToSet){
        setValue(valueToSet);
        setMementoValue([...mementoValue, valueToSet]);
    }

    function handleChange(e) {
        const value = e && e.target ? e.target.value : e;
        setMementoValue([...mementoValue, value]);
        setValue(value);
    }

    function rollback() {
        if (!mementoValue || mementoValue.length <= 1)
            return;

        const previousValue = mementoValue;
        previousValue.pop();
        setMementoValue(previousValue);
        setValue(mementoValue[mementoValue.length - 1]);
    }

	return {
		onChange: handleChange,
		rollback,
        setStateValue,
		value
	};
}

export function useResizeWindow() {
    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    function resizeWindow(){
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    }

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    });

    return {
        windowHeight,
        windowWidth
    }
}

export function isArray(value) {
    return (typeof value === 'object' && value.constructor === Array);
}

export function isArrayNullOrEmpty(arr) {
    return !(isArray(arr) && arr.length !== 0);
}

export function convertCamelCaseToSpaceDelimited(string = "") {
    const regex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    return string.replace(regex, '$1$4 $2$3$5');
}

export function createListFromObject(object) {
    let result = [];
    for (let property in object) {
        if (object.hasOwnProperty(property)) {
            result.push(createListItem(object[property], convertCamelCaseToSpaceDelimited(object[property]), property));
        }
    }
    return result;
}

export function createObjectFromArray(array) {
    let object = {};

    if (isArrayNullOrEmpty(array)) return object;

    array.map((item) => {
        return object = Object.assign(object, {[item]: item});
    });

    return object;
}

export function createListItem(value, text, id) {
    return {
        id: id ? id : value,
        label: text ? text : value,
        text: text ? text : value,
        value: value
    };
}

export function isInSelectList(list, string) {
    return list && !!list.filter(item => item.value === string || item.text === string).length;
}

export function getSelectedValuesAsInt(list) {
    if (!list)
        return list;

    return list.map(item => parseInt(item.value));
}