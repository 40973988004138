import React from "react";
import {PAGES as style} from "../../Helpers/styleconstants";
import Banner from "../Common/Banner";

function DecalPage({language}) {
    const decal = require('../../Data/DecalPage.json');

    let i = 0;
    return (
        <>
            <Banner page={"decal"} language={language}/>
            <div className={style.container}>
                <div className={style.header}>{decal[language].pageTitle}</div>
                <div className={style.pageContent}>
                    {decal[language].text.map(text => {
                        return (
                            <p key={i++}>
                                {text.p}
                            </p>)
                    })}


                    <p><a href={decal[language].decalLink.url} target="_blank" rel="noopener noreferrer">{decal[language].decalLink.display}</a></p>

                    {decal[language].links.title}<br/>
                    <ul>
                        {decal[language].links.bullets.map(bullet => {
                            return (
                                <li key={i++}>
                                    <a href={bullet.url} target="_blank" rel="noopener noreferrer">{bullet.display}</a>
                                </li>
                            )})}
                     </ul>
                </div>
            </div>
        </>
    )
}

export default DecalPage;