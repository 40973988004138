import React from "react";
import {RESOURCES_PAGE as style} from "../../Helpers/styleconstants";
import ResourceLink from "./ResourceLink";

function ResourceNavigation({resourcesCollection,  selectedResourceCategory, isSelected, buttonClick}) {
    let i = 0;
    return (
        <>
            <ul className={style.resourceLinkNavigation}>
                {resourcesCollection.map(resourceCollection => {
                    return (
                        <li key={i++}>
                            <ResourceLink resourceCollection={resourceCollection} isSelected={isSelected} />
                        </li>
                    )
                })}
            </ul>
        </>
    );
}

export default ResourceNavigation;