import React from "react";
import { useParams } from 'react-router-dom';
import { RESOURCES_PAGE as style } from "../../Helpers/styleconstants";
import Banner from "../Common/Banner";
import Resource from "../Resources/Resource";
import ResourceNavigation from "../Resources/ResourceNavigation";

function ResourcesPage({language}) {
    const resources = require('../../Data/Resources.json');
    const {categoryId} = useParams();

    let i = 0;
    return (
        <>
            <Banner page={"resources"} language={language}/>
            <div className={style.container}>
                <div className={style.header}>{resources[language].pageTitle}</div>
                <div>
                    {resources[language].text.map(text => {
                        return (
                            <p key={i++} dangerouslySetInnerHTML={ { __html: text.p } }>
                            </p>)
                    })}
                </div>
                <div className={style.resourceMainContainer}>
                    <div className={style.resourceNavigationContainer}>
                        <ResourceNavigation resourcesCollection={resources[language].resourceCollection} />
                    </div>
                    <div className={style.resourceContentContainer}>
                        {typeof categoryId != "undefined" &&
                            <div>
                                <div className={style.resourceHeroContainer + resources[language].resourceCollection[categoryId-1].category[0].key}>
                                </div>
                                <div className={style.resourceCategoryText}>
                                    <h3>{resources[language].resourceCollection[categoryId-1].category[0].title}</h3>
                                    <p dangerouslySetInnerHTML={ { __html: resources[language].resourceCollection[categoryId-1].category[0].text} }>
                                    </p>
                                </div>
                                <div className={style.resourceCollectionContainer}>
                                    <Resource resourceCollection={resources[language].resourceCollection[categoryId-1]} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResourcesPage;