import React, {useEffect, useState} from "react";
import MilestoneRow from "../Milestones/MilestoneRow";
//import MilestoneTipsRow from "../MilestoneTips/MilestoneTipsRow";
import {DOMAIN_TABLE as style} from "../../Helpers/styleconstants";
import OpenCloseArrow from "./OpenCloseArrow";
import OpenCloseArrowButton from "./OpenCloseArrowButton";

function Domain({descriptions, selectedAges, language}) {
    const [isArrowVisible, setArrowState] = useState(false);
    let idArray = descriptions.map(des => (des.id));
    const [showMilestones, setShowMilestones] = useState(idArray);
    const [useFullDescription, setFullDescription] = useState(true);

    useEffect(() => {
        setArrowState(false);
        setFullDescription(true);
        if (selectedAges.length > 0) {
            setArrowState(true);
            setShowMilestones(idArray);
            setFullDescription(false);
        }
    }, [selectedAges], [showMilestones]);

    function handleArrowClick(result) {
        if (!result.isVisible && showMilestones.includes(result.id))
            setShowMilestones(showMilestones.filter(ms => ms !== result.id));

        if (result.isVisible && !showMilestones.includes(result.id))
            setShowMilestones([...showMilestones, result.id]);
    }

    return (
        <>
            {descriptions.map(description => {
                return (
                    <div id={style.category + description.id} className={style.category + description.id} key={description.icon}>
                        {
                            description.id !== "tips" &&
                            <div className={style.row}>
                                <div className={useFullDescription ? style.domainDescriptionCell: style.domainDescriptionCell + " " + description.id}>
                                    <div className={style.table}>
                                        <div className={style.tableRow + " " + description.id }>
                                            <div className={style.tableHeader}>
                                                {description.heading}
                                            </div>
                                        </div>
                                        <div className={style.tableRow}>
                                            <div className={style.tableColumnDescription}>
                                                {
                                                    useFullDescription ? description.full : description.summary
                                                }
                                            </div>
                                            <div className={style.tableColumnArrow}>
                                                {
                                                    isArrowVisible &&
                                                    <OpenCloseArrow id={description.id} onClick={handleArrowClick}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            showMilestones.includes(description.id) && description.id !== "tips"
                            && <MilestoneRow selectedAges={selectedAges} domain={description.id} language={language}/>
                        }
                        {
                            description.id === "tips" &&
                            <div className={style.row}>
                                <div className={style.domainArrowContainer}>
                                    {
                                    isArrowVisible &&
                                    <div className={style.tableColumnArrow}>
                                            <OpenCloseArrowButton id={description.id} text={description.buttonName} onClick={handleArrowClick}/>
                                    </div>
                                    }
                                </div>
                                <div className={useFullDescription ? style.domainDescriptionCell: style.domainDescriptionCell + " " + description.id}>
                                    <div className={style.table}>
                                        <div className={style.tableRow + " " + description.id }>
                                            <div className={style.tableHeader}>
                                                {description.heading}
                                            </div>
                                        </div>
                                        <div className={style.tableRow}>
                                            <div className={style.tableColumnDescription}>
                                                {
                                                    useFullDescription ? description.full : description.summary
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            showMilestones.includes(description.id) && description.id === "tips"
                            && <MilestoneRow selectedAges={selectedAges} domain={description.id} language={language}/>
                        }
                    </div>
                )
            })}
        </>
    );
}

export default Domain;