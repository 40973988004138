import React from "react";

export const LanguageSwitchButton = ({languageOptions, userLanguage, handleLanguageChange}) => {
    
    return (
        <select className={"language-selector"} onChange={handleLanguageChange} value={userLanguage}>
            {Object.entries(languageOptions).map(([id, name]) => (
                <option className={"language-selector-contents"} key={id} value={id}>{name}</option>
            ))}
        </select>
    );
}

export default LanguageSwitchButton;