import React, {useState} from "react";
import {STICKY_HEADER as style} from "../../Helpers/styleconstants";
import ResourceMenu from "./ResourceMenu";
import LanguageSwitchButton from "../Domain/LanguageSwitchButton";

function StickyHeader({userLanguage, handleLanguageChange}) {
    const header = require("../../Data/Header.json");
    const [isMenuVisible, setMenuVisible] = useState(false);

    const handleMenuClick = (e) => {
        setMenuVisible(false);
        if (!isMenuVisible)
            setMenuVisible(true);
    };

    const handleLogoClick = (e) => {
        e.preventDefault();
        window.location.href = "http://decal.ga.gov/";
    };

    return (
        <div className={style.stuckHeader}>
            <div className={style.menuCell} onClick={handleMenuClick}>
                <span className={style.navigationIcon}></span>
            </div>
            <div className={style.logoCell} onClick={handleLogoClick}></div>
            <div className={style.titleCell}><h1>{header[userLanguage].headerTitle}</h1></div>
            <div className={style.titleCell}>
                <div className={"language-button-container"}>
                    Language:&nbsp;
                    <LanguageSwitchButton languageOptions={header.languages}
                        userLanguage={userLanguage}
                        handleLanguageChange={handleLanguageChange}/>
                </div>
            </div>
            <nav role="navigation">
                {
                    isMenuVisible &&
                    <ResourceMenu language={userLanguage} handleMenuClick={handleMenuClick}/>
                }
            </nav>
        </div>
    );
}

export default StickyHeader;