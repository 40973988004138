import React, {useState, useEffect} from "react";
import {DOMAIN_TABLE as style} from "../../Helpers/styleconstants";

function OpenCloseArrow({id, onClick}) {
    const [arrowClass, setArrowClass] = useState("icon-caret-up");
    const [isUp, setArrowDirection] = useState(false);

    useEffect(() => {
        isUp ? setArrowClass("icon-caret-up") : setArrowClass("icon-caret-down")
    }, [isUp]);

    const handleClick = () => {
        isUp ? setArrowDirection(false) : setArrowDirection(true);
        onClick({id, isVisible: isUp});
    };

    return (
        <div className={style.hideMilestones} onClick={handleClick}>
            <i className={arrowClass}></i>
        </div>
    );

}

export default OpenCloseArrow;