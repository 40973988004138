import React from "react";
import { PAGES as style } from "../../Helpers/styleconstants";
import { CONTAINERS as containerstyle } from "../../Helpers/styleconstants";
import Banner from "../Common/Banner";
import cdcAppImageIOS from "../../Images/APPLE-APP-Store-Icon.png";
import cdcAppImageAndroid from "../../Images/GOOGLE-play-badge.png";
import cdcAppImagePhone from "../../Images/CDC Tracker App Phone Icon.png";

function DevelopmentalChecklistsPage({language}) {
    const devCheckList = require('../../Data/DevelopmentalChecklistPage.json');
    let i = 0;

    return (
        <>
            <Banner page={"resources"} language={language}/>
            <div className={style.container}>
                <div className={style.header}>{devCheckList[language].pageTitle}</div>
                <p>
                    <a href={devCheckList[language].cdcLink.url} target="_blank" rel="noopener noreferrer">{devCheckList[language].cdcLink.display}</a>
                </p>
                {devCheckList[language].text.map(text => {
                    return (
                        <p key={i++}>
                            {text.p}
                        </p>)
                })}
                <div className="grid-x">
                    <div className={containerstyle.containerCdcChecklist}>
                        <p style={{fontWeight:"bold"}}>{devCheckList[language].checklist.title}</p>
                        <ul>
                            {devCheckList[language].checklist.text.map(text => {
                                return (
                                    <li key={i++}>
                                        <span dangerouslySetInnerHTML={{__html: text.p}}></span>
                                    </li>
                                )})}
                            {devCheckList[language].links.bullets.map(bullet => {
                                return (
                                    <li key={i++}>
                                        <span>{bullet.title}</span>&nbsp;
                                        (<a href={bullet.urlEnglish} target="_blank" rel="noopener noreferrer">{bullet.displayEnglish}</a>)&nbsp;
                                        (<a href={bullet.urlSpanish} target="_blank" rel="noopener noreferrer">{bullet.displaySpanish}</a>)&nbsp;
                                        (<a href={require('../../Checklists/' + bullet.urlChinese)} target="_blank" rel="noopener noreferrer">{bullet.displayChinese}</a>)&nbsp;
                                        (<a href={require('../../Checklists/' + bullet.urlKorean)} target="_blank" rel="noopener noreferrer">{bullet.displayKorean}</a>)&nbsp;
                                        (<a href={require('../../Checklists/' + bullet.urlVietnamese)} target="_blank" rel="noopener noreferrer">{bullet.displayVietnamese}</a>)&nbsp;
                                        (<a href={bullet.urlOnline} target="_blank" rel="noopener noreferrer">{bullet.displayOnline}</a>)
                                    </li>
                                )})}
                        </ul>
                    </div>
                    <div className={containerstyle.containerCdcTrackerApp}>
                        <div>
                            <p style={{fontWeight:"bold"}}>{devCheckList[language].trackerApp.title}</p>
                        </div>
                        <div className={containerstyle.containerCdcTrackerAppLinks}>
                            <div>
                                <a className={containerstyle.imageCdcTrackerAppIOS} href={devCheckList[language].trackerApp.linkIOS} target="_blank" rel="noopener noreferrer"><img src={cdcAppImageIOS} alt={devCheckList[language].trackerApp.imageIOSalt}/></a>
                            </div>
                            <div>
                                <a className={containerstyle.imageCdcTrackerAppAndroid} href={devCheckList[language].trackerApp.linkAndroid} target="_blank" rel="noopener noreferrer"><img src={cdcAppImageAndroid} alt={devCheckList[language].trackerApp.imageAndroidalt}/></a>
                            </div>
                        </div>
                        <div>
                            <img className={containerstyle.imageCdcTrackerAppPhone} src={cdcAppImagePhone} alt="" />
                            {devCheckList[language].trackerApp.text.map(text => {
                                return (
                                    <p key={i++}>
                                        {text.p}
                                    </p>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DevelopmentalChecklistsPage;