import React from "react";
import {RESOURCES_PAGE as style} from "../../Helpers/styleconstants";

function Resource({resourceCollection}) {
    let i = 0;

    return (
        <>
            {resourceCollection.resources.map(resource => {
                return (
                    <div className={style.resourceItemContainer} key={i++}>
                        {resource.title !== "" &&
                            <h4 dangerouslySetInnerHTML={ { __html: resource.category } }></h4>
                        }
                        <p>
                        {resource.url !== "" &&
                            <a href={resource.url} target="_blank" rel="noopener noreferrer"><strong>{resource.name}</strong></a> 
                        }
                        {resource.url === "" &&
                            <strong>{resource.name}</strong> 
                        }
                            <span dangerouslySetInnerHTML={ { __html: " " + resource.text } }></span><br/>
                        </p>
                    </div>
                )
            })}
        </>
    );
}

export default Resource;