import React from "react";
import {FOOTER as style} from "../../Helpers/styleconstants";

export const Footer = ({language}) =>
{
    const footerData = require('../../Data/Footer.json');
    return (

        <div className={style.footerContainer}>
            <div className={style.footerContent}>
                <hr/>
                <p>
                    <span dangerouslySetInnerHTML={ { __html: footerData[language].technicalSupport.text } }></span>
                    &nbsp;
                    <a href={footerData[language].technicalSupport.link.url}>
                        {footerData[language].technicalSupport.link.display}
                    </a>
                </p>
                <hr/>
                <p dangerouslySetInnerHTML={ { __html: footerData[language].copyright } }>
                </p>
                <p dangerouslySetInnerHTML={ { __html: footerData[language].cdc } }>
                </p>
                <p dangerouslySetInnerHTML={ { __html: footerData[language].funding } }>
                </p>
            </div>
        </div>
)};