export const APP_ENTRY = {
    content: "content"
};

export const STICKY_HEADER = {
    stuckHeader: "grid-x header sticky",
    menuCell: "cell small-order-1 medium-order-1 large-order-1 small-2 medium-1 large-1 menu",
    logoCell: "cell small-order-3 medium-order-2 large-order-2 small-4 medium-2 large-1 logo",
    titleCell: "cell small-order-2 medium-order-3 large-order-3 small-6 medium-auto large-auto title",
    navigationIcon: "icon-navicon",
    menuContainer: "menu-container",
    menuItems: "menu-items"
};

export const BANNER = {
    bannerContainer: "grid-x banner-container",
    bannerImage: "cell",
    bannerTitle: "banner-title cell",
    bannerText: "banner-text cell"

};

export const FOOTER = {
  footerContainer: "grid-x footer-container",
  footerContent: "cell"
};

export const AGE_BUTTON = {
    buttonContainer: "age-button-container",
    buttonImageContainer: "age-button-image-container",
    buttonLabel: "age-button-label",
    buttonGroup: "age-button-row grid-x",
    buttonContents: "age-button-contents align-self-middle",
    buttonText: "age-button-text align-self-middle",
    button: "age-button cell",
    selected: "age-button-selected cell"
};

export const MILESTONE_CELL = {
    milestoneCellRow: "grid-y large-auto milestone",
    milestoneAgeHeaderCell: "cell milestone-border milestone-header",
    milestoneAgeHeaderTextCell: "milestone-border text-center milestone-header-text",
    milestoneDetailCell: "cell milestone-border flex-child-grow milestone-padding"
};

export const MILESTONE_ROW = {
    milestoneRow: "grid-x grid-padding-x",
    milestoneAgeHeaderCell: "cell milestone-border text-center",
    milestoneDetailCell: "cell milestone-border flex-child-grow milestone-padding"
};

export const DOMAIN_TABLE = {
    category: "category-",
    row: "grid-x grid-padding-x",
    domainImageCell: "cell domain hide-for-small-only medium-2 large-2",
    domainDescriptionCell: "cell domain small-12 medium-12 large-12",
    domainArrowContainer: "cell domain small-12 medium-12 large-12 arrow-container",
    hideMilestones: "cell domain flex-arrow",
    table: "domain-table",
    tableRow: "domain-table-row",
    tableHeader: "domain-table-header",
    tableColumn: "domain-table-column",
    tableColumnDescription: "domain-table-column description",
    tableColumnArrow: "domain-table-column arrow",
};

export const TIPS_TABLE = {
    tipsCategory: "category-tips-new",
    row: "grid-x grid-padding-x",
    tipsImage: "tips-image",
    tipsImageCell: "cell tips hide-for-small-only medium-2 large-2",
    tipsDescriptionCell: "cell tips small-auto medium-auto large-auto",
    tipsSpace: "grid-x tips-space-padding",
    table: "tips-table",
    tableRow: "tips-table-row",
    tableHeader: "tips-table-header",
    tableColumn: "tips-table-column"
};

export const WARNING_TABLE = {
    warningCategory: "category-warning-new",
    row: "grid-x grid-padding-x",
    warningImage: "warning-image",
    warningImageCell: "cell warning hide-for-small-only medium-2 large-2",
    warningDescriptionCell: "cell warning small-auto medium-auto large-auto",
    warningSpace: "grid-x warning-space-padding",
    table: "warning-table",
    tableRow: "warning-table-row",
    tableHeader: "warning-table-header",
    tableColumn: "warning-table-column"
};

export const PAGES = {
    container: "page-container",
    header: "heading",
    subheader: "subheader",
    pageContent: "page-content",
};

export const ACTEARLY_PAGE = {
    container: "page-container",
    header: "heading",
    subheader: "subheader",
    pageContent: "page-content",
    actEarlyMainContainer: "actearly-container-main",
    actEarlyItemContainer: "actearly-container-item grid-x grid-padding-x",
    actEarlyItemBabyImageContainer: "cell small-12 medium-4 large-4",
    actEarlyItemBabyContentContainer: "cell small-12 medium-8 large-8",
    actEarlyStepLargeContainer: "actearly-container-large cell small-12 medium-12 large-12",
    actEarlyStepLargeContainerBuffer: "actearly-container-small-buffer cell hide-for-small medium-2 large-2",
    actEarlyStepSmallContainer: "actearly-container-small cell small-12 medium-6 large-6",
    actEarlyStepSmallContainerBuffer: "actearly-container-small-buffer cell hide-for-small medium-3 large-3",
    actEarlyStepContentContainer: "actearly-container-content",
};

export const RESOURCES_PAGE = {
    container: "page-container",
    header: "heading",
    subheader: "subheader",
    pageContent: "page-content",
    selected: "resource-link-selected",
    resourceMainContainer: "resource-main-container grid-x grid-padding-x",
    resourceNavigationContainer: "resource-navigation-container cell small-12 medium-3 large-2",
    resourceContentContainer: "resource-content-container cell small-12 medium-auto large-auto",
    resourceCategoryText: "resource-category-text",
    resourceHeroContainer: "resource-hero-container resource-hero-",
    resourceCollectionContainer: "resource-collection-container",
    resourceItemContainer: "resource-item-container",
    resourceLinkNavigation: "resource-link-navigation",
    resourceLinkContainer: "resource-link-container",
    resourceLinkText: "resource-link-text"
};

export const CONTAINERS = {
    containerCdcChecklist: "container-cdc-checklist",
    containerCdcTrackerApp: "container-cdc-tracker-app",
    containerCdcTrackerAppLinks: "container-cdc-tracker-app-links",
    imageCdcTrackerAppIOS: "cdc-app-ios",
    imageCdcTrackerAppAndroid: "cdc-app-android",
    imageCdcTrackerAppPhone: "cdc-app-phone",
};