import React, {useState, useEffect} from "react";
import Scroll from "react-scroll";
import Select from 'react-select';
import Domain from "../Domain/Domain";

import {getAgeLabels} from "../../repository/milestonesRepository";
import AgeButtonGroup from "../Domain/AgeButtonGroup";
import {getSelectedValuesAsInt, useMemento, useResizeWindow} from "../../Helpers/utilities";
import Warning from "../Warnings/Warning";
import Banner from "../Common/Banner";

import {DOMAIN_TABLE as style} from "../../Helpers/styleconstants";

const HomePage = ({language}) => {
    const domains = require('../../Data/Descriptions.json');
    const [selectedAges, setSelectedAges] = useState([]);
    const [showWarning, setShowWarning] = useState(false);
    const [showSelect, setShowSelect] = useState(false);

    const sortAges = (ages) => {
        return ages.sort((a, b) => parseInt(a) - parseInt(b));
    };

    const buttonClick = age => {
        const ages = [...selectedAges];
        if (ages.length >= 3 && !ages.includes(age))
            return;

        let index = ages.indexOf(age);
        if (index > -1)
            ages.splice(index, 1);
        else {
            ages.push(age);
        }
        setSelectedAges(sortAges(ages));
        setShowWarning(false);
        if (ages && ages.length > 0)
            setShowWarning(true);
    };

    const ageLabels = getAgeLabels(language);
    const dropDownSelectedAges = useMemento(null);
    const windowWidth = useResizeWindow().windowWidth;

    useEffect(() => {
        setShowSelect(false);
        if (windowWidth && windowWidth < 1200) {
            setShowSelect(true);
            if (selectedAges.length > 0) {
                let selections = ageLabels.filter((label) => {
                    return selectedAges.includes(label.value);
                });
                dropDownSelectedAges.setStateValue(selections);
            }
        }
    }, [windowWidth]);

    useEffect(() => {
        let selectedAgeValues = dropDownSelectedAges.value;
        if (!selectedAgeValues) {
            setSelectedAges([]);
            setShowWarning(false);
            return;
        }

        setShowWarning(true);
        let ages = getSelectedValuesAsInt(selectedAgeValues);

        if (ages.length > 3) {
            dropDownSelectedAges.rollback();
            return;
        }
        setSelectedAges(sortAges(ages));
    }, [dropDownSelectedAges.value]);

    const getScrollConfig = () => {
        const scrollConfig = {
            duration: 300,
            delay: 0,
            smooth: true
        };

        return scrollConfig;
    }

    const handleScroll = (containerId) => {
        const scroller = Scroll.scroller;
        window.setTimeout(() => {
            scroller.scrollTo(containerId, getScrollConfig())
        }, 300);
    }

    const placeholder = (language === "spanish")
    ? <p>Seleccione hasta 3 grupos de edad</p>
    : (language === "mandarin")
    ? <p>最多选择3个年龄组</p>
    : (language === "korean")
    ? <p>최대 3가지 연령 그룹 선택</p>
    : (language === "vietnamese")
    ? <p>Chọn tối đa 3 Nhóm tuổi</p>
    : <p>Select up to 3 Age Groups</p>

    return (
        <>
            <Banner page={"home"} language={language}/>
            <div className={style.header}>{domains[language].PageTitle}</div>
            <hr/>
            {
                showSelect ?
                    <Select
                        id={`selectAges`}
                        isMulti
                        options={ageLabels}
                        {...dropDownSelectedAges}
                        placeholder={placeholder}
                    />
                    :
                    <AgeButtonGroup ageLabels={ageLabels} selectedAges={selectedAges} buttonClick={buttonClick} language={language}/>
            }
                <div className={style.category + "tips"} onClick={() => {handleScroll("category-tips")}}>
                    <div className={style.row}>
                        <div className={style.domainDescriptionCell + " tips-button"}>
                            <div className={style.table}>
                                <div className={style.tableRow + " tips" }>
                                    <div className={style.tableHeader}>
                                        {domains[language].tips.heading}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <Warning warning={domains[language].warning} selectedAges={selectedAges} language={language}/>

            <Domain descriptions={domains[language].Descriptions} selectedAges={selectedAges} language={language}/>

            {/* {
                showWarning &&
                <Warning warning={domains[language].warning} selectedAges={selectedAges} language={language}/>
            } */}

        </>
    )
}

export default HomePage