import React from "react";
import {NavLink} from "react-router-dom";
import {STICKY_HEADER as style} from "../../Helpers/styleconstants";

function ResourceMenu({language, handleMenuClick}) {
    const header = require("../../Data/Header.json");
    return (
        <div className={style.menuContainer}>
            <ul className={style.menuItems} onClick={handleMenuClick}>
                <li>
                    <NavLink to="/">{header[language].resourceMenu.home}</NavLink>
                </li>
                <li>
                    <NavLink to="/checklist">{header[language].resourceMenu.developmentalChecklists}</NavLink>
                </li>
                <li>
                    <NavLink to="/actearly">{header[language].resourceMenu.actEarly}</NavLink>
                </li> 
                <li>
                    <NavLink to="/resources/1">{header[language].resourceMenu.additionalResources}</NavLink>
                </li> 
                <li>
                    <NavLink to="/decal">{header[language].resourceMenu.DECAL}</NavLink>
                </li>
                <li>
                    <NavLink to="/gelds">{header[language].resourceMenu.GELDS}</NavLink>
                </li>
            </ul>
        </div>
    )
}

export default ResourceMenu;