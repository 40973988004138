import React from "react";
import {PAGES as style} from "../../Helpers/styleconstants";
import Banner from "../Common/Banner";

function GeldsPage({language}) {
    const gelds = require('../../Data/GeldsPage.json');
    let i = 0;

    return (
        <>
            <Banner page={"gelds"} language={language}/>
            <div className={style.container}>
                <div className={style.header}>{gelds[language].pageTitle}</div>
                <div className={style.pageContent}>

                        {gelds[language].text.map(text => {
                            return (
                                <p key={i++} dangerouslySetInnerHTML={{__html: text.p}}>
                                </p>)
                        })}

                    <p><a href={gelds[language].geldsLink.url} target="_blank" rel="noopener noreferrer">{gelds[language].geldsLink.display}</a></p>

                </div>
            </div>
        </>
    )
}

export default GeldsPage;