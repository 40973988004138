import React, { useRef, useEffect } from "react";

const Modal = ({ video, images, desc, ageLabel, ageAbbreviation, show, handleClose, language, closeText, downloadText }) => {
    let counter = 0;
    console.log(language);

    const modalWindow = useRef(null);

    useEffect(() => {
        document.getElementById("modalWindowId").focus();
    }, [])

    useEffect(() => {
        const handleKeyDown = (e) => {
            if(e.key === "Escape"){
                handleClose();
            }
            if (e.key === "Tab") {
                if (document.activeElement === document.getElementById("endOfModal")) {
                    document.getElementById("modalWindowId").focus();
                }
            }
            if (e.shiftKey && e.key === 'Tab') {
                document.getElementById("modalWindowId").focus();
            }
        }
        window.addEventListener('keydown', handleKeyDown)
        return () => window.removeEventListener('keydown', handleKeyDown)
    }, [])

    return (
        <div className="modalWindow" id="modalWindowId" ref={modalWindow} aria-label="modal window" role="dialog" aria-hidden={!show} onClick={handleClose} tabIndex={0}>

            { video &&
                <div className={`modalWindow-contents`} onClick={e => e.stopPropagation()}>
                <button className={`modalWindow-contents-closeButton`} onClick={handleClose}>{closeText}</button>
                <h3 className={`modalWindow-contents-description`}>{ageLabel} - {desc}</h3>
                    <video width="100%" height="100%" controls autoPlay preload="metadata"
                    src={require(`../../Images/AgeGroups/${ageAbbreviation}${video}`)} type="video/mp4"/>
                </div>
            }

            { images &&
                <div className={`modalWindow-contents`} onClick={e => e.stopPropagation()}>
                <button className={`modalWindow-contents-closeButton`} onClick={handleClose}>{closeText}</button>
                <h3 className={`modalWindow-contents-description`}>{ageLabel} - {desc}</h3>
                    {typeof images === "object" &&
                        images.map(image => {
                            counter++;
                            return (
                                <div aria-describedby="Image container" key={counter} >
                                    <img src={require(`../../Images/AgeGroups/${ageAbbreviation}${image}`)} alt={desc} />
                                    <a href={require(`../../Images/AgeGroups/${ageAbbreviation}${image}`)} target="_blank" rel="noopener noreferrer" >{downloadText}</a>
                                </div>
                            );
                        })
                    }
                    {typeof images === "string" &&
                        <div aria-describedby="Image container">
                            <img src={require(`../../Images/AgeGroups/${ageAbbreviation}${images}`)} alt={desc} />
                            <a href={require(`../../Images/AgeGroups/${ageAbbreviation}${images}`)} target="_blank" rel="noopener noreferrer" >{downloadText}</a>
                        </div>
                    }
                </div>
            }
            <a href="#" aria-label="end of modal" id="endOfModal"></a>
        </div>
    )
}

export default Modal;