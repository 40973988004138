
const milestones = require( "../Data/Milestones.json");

export const getMilestone = (months, language) => {
    let result = milestones[language].milestones.filter((milestones)=>{return milestones.ageInMonths === months});
    return result;
};

export const getAgeLabels = (language) => {
    return milestones[language].milestones.map(item => ({value: item.ageInMonths, label: item.label, ageNumber: item.ageNumber, ageUnit: item.ageUnit}));
};

export const getLanguageOperators = (language) => {
    let close = milestones[language].close;
    let download = milestones[language].download;

    return { close: close, download: download };
}