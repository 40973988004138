import React from "react";
import {AGE_BUTTON as style} from "../../Helpers/styleconstants";

function AgeButton({buttonTags, isSelected, buttonClick}) {
    return (
        <div className={isSelected ? style.selected : style.button} id={buttonTags.value} onClick={() => buttonClick(buttonTags.value)}>
            <div className={style.buttonImageContainer + " age-button-image-" + buttonTags.value}>
                <div className={style.buttonContents + " age-button-color-" + buttonTags.value}>
                    <div className={style.buttonText}>
                        <div>
                            <span>{buttonTags.ageNumber}</span> {buttonTags.ageUnit}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AgeButton;