import React, { useState } from "react";
import { MILESTONE_CELL as style } from "../../Helpers/styleconstants";
import Modal from "../Common/Modal";

function MilestoneCell({ milestoneCount, milestone, age, ageLabel, ageAbbreviation, language, modalOperators }) {

    const [modalState, setModalState] = useState({
                                            video: "",
                                            images: "",
                                            desc: "",
                                            ageLabel: "",
                                            ageAbbreviation: "",
                                            show: false,
                                            handleClose: "",
                                            language: "",
                                            closeText: "",
                                            download: "",
                                        })

    function hideModal() {
        setModalState(!modalState.show);
    }

    console.log(modalOperators);
    function showModal({ video, images, desc, ageAbbreviation, show, handleClose, closeText, downloadText }) {
        setModalState(previousModalState => ({
            ...previousModalState,
            video: video,
            images: images,
            desc: desc,
            show: show,
            ageLabel: ageLabel,
            ageAbbreviation: ageAbbreviation,
            handleClose: handleClose,
            closeText: modalOperators.close,
            downloadText: modalOperators.download,
            language: language
        }));
    }

    let i = 0;
    let singleMilestoneClass = "";
    if(!milestone)
        return null;
    if (milestoneCount === 1)
        singleMilestoneClass = "single-milestone";

    return (
        <div className={style.milestoneCellRow}>
            <div className={style.milestoneAgeHeaderCell + " milestone-age-" + age + " " + singleMilestoneClass}>
                <div className={style.milestoneAgeHeaderTextCell}>
                    <div>
                        {ageLabel}
                    </div>
                </div>
            </div>
            <div className={style.milestoneDetailCell + " " + singleMilestoneClass}>
                <ul>
                    {milestone.map(bullet => {
                        i++;
                        return (
                            <li key={i}>
                                { bullet.text }
                                {bullet.linkType === "Video"
                                    ? <button className="milestone-button" onClick={() => showModal({ video: bullet.linkUrl, desc: bullet.text, show: true, ageLabel: ageLabel, ageAbbreviation: ageAbbreviation, handleClose: hideModal, closeText: modalOperators.closeText, downloadText: modalOperators.downloadText })}><img className="milestone-buttonIcon" src={require("../../Images/Icons/Video Icon.png")} alt="video of milestone" /></button>
                                    : bullet.linkType === "Image"
                                        ? <button className="milestone-button" onClick={()=>showModal({ images: bullet.linkUrl, desc: bullet.text, show: true, ageLabel: ageLabel, ageAbbreviation: ageAbbreviation, handleClose: hideModal, closeText: modalOperators.closeText, downloadText: modalOperators.downloadText })}><img className="milestone-buttonIcon" src={require("../../Images/Icons/Image Icon.png")} alt="image or images of milestone" /></button>
                                        : ""
                                }
                                { bullet.linkType2 === "Video"
                                    ? <button className="milestone-button" onClick={()=>showModal({ video: bullet.linkUrl2, desc: bullet.text, show: true, ageLabel: ageLabel, ageAbbreviation: ageAbbreviation, handleClose: hideModal, closeText: modalOperators.closeText, downloadText: modalOperators.downloadText })}><img className="milestone-buttonIcon" src={require("../../Images/Icons/Video Icon.png")} alt="video of milestone" /></button>
                                    : bullet.linkType2 === "Image"
                                        ? <button className="milestone-button" onClick={()=>showModal({ images: bullet.linkUr2l, desc: bullet.text, show: true, ageLabel: ageLabel, ageAbbreviation: ageAbbreviation, handleClose: hideModal, closeText: modalOperators.closeText, downloadText: modalOperators.downloadText })}><img className="milestone-buttonIcon" src={require("../../Images/Icons/Image Icon.png")} alt="image or images of milestone" /></button>
                                        : ""
                                }
                            </li>
                        )
                    })}
                </ul>
            </div>
            {modalState.show && <Modal video={modalState.video} images={modalState.images} desc={modalState.desc} ageLabel={modalState.ageLabel} ageAbbreviation={modalState.ageAbbreviation} show={modalState.show} handleClose={modalState.handleClose} language={modalState.language} closeText={modalState.closeText} downloadText={modalState.downloadText}/>}
        </div>
    );
}

export default MilestoneCell;